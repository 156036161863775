/** @format */

import Vue from 'vue'
import vueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(vueCompositionApi)

const state = reactive({
	isDispatchesVisible: false,
	keyFrame: 1,
	// ---------------------------------------------------------------------------
	q_customer_id: '',
	q_address_id: '',
	q_bd: '',
	q_ed: '',
})

export default function useRevenueCenterStatisticsState() {
	return {
		...toRefs(state),
	}
}
