<template>
	<div>
		<div>
			<div>
				<div
					style="
						margin-top: 50px;
						margin-bottom: 40px;
						font-weight: 700;
						font-size: 20px;
					"
				>
					<div>Customer: {{ customer.customerInfo }}</div>
					<div>
						Delivery Adress: {{ customer.addressNickName }} -
						{{ customer.address }}
					</div>
					<div>Counted Dispatch(es): {{ customer.dispatchAmount }}</div>
				</div>
				<div v-for="dispatch in dispatches" :key="dispatch.dispatchNumber">
					<div style="margin-bottom: 10px">
						<span style="color: black; font-size: large">
							{{ dispatch.dispatchNumber }}&nbsp; - &nbsp;
							{{ dispatch.dispatchDate }}&nbsp; - &nbsp; All values are in
							&nbsp;<span style="color: red; font-weight: 700">{{
								dispatch.dispatchExchangeUnit
							}}</span>
						</span>
					</div>
					<div>
						<vue-good-table :columns="columns" :rows="dispatch.dispatchLines">
							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field === 'amount'">
									<p class="text-blue text-red text-align-center">
										{{ props.row[props.column.field] }}
									</p>
								</span>
								<span v-else-if="props.column.field === 'price'">
									<p class="text-bold text-black text-align-right">
										{{
											numeral(props.row[props.column.field] / 100).format(
												'0,0.00',
											)
										}}
									</p>
								</span>
								<span v-else-if="props.column.field === 'lineTotal'">
									<p class="text-bold text-black text-align-right">
										{{
											numeral(props.row[props.column.field] / 100).format(
												'0,0.00',
											)
										}}
									</p>
								</span>
								<span v-else-if="props.column.field === 'lineVAT'">
									<p class="text-bold text-black text-align-right">
										{{
											numeral(props.row[props.column.field] / 100).format(
												'0,0.00',
											)
										}}
									</p>
								</span>
								<span v-else-if="props.column.field === 'lineGrandTotal'">
									<p class="text-bold text-black text-align-right">
										{{
											numeral(props.row[props.column.field] / 100).format(
												'0,0.00',
											)
										}}
									</p>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
							<template slot="table-column" slot-scope="props">
								<span v-if="props.column.label !== 'Product'">
									<p class="text-bold text-align-center">
										{{ props.column.label }}
									</p>
								</span>
								<span v-else>
									{{ props.column.label }}
								</span>
							</template>
						</vue-good-table>
						<div
							style="margin-bottom: 40px; background-color: rgb(243, 244, 247)"
						>
							<table class="my-table">
								<tr>
									<td>Dispatch Total</td>
									<td>
										{{ numeral(dispatch.linesTotal / 100).format('0,0.00') }}
									</td>
									<td>kr.</td>
								</tr>
								<tr>
									<td>V.A.T.</td>
									<td>
										{{ numeral(dispatch.linesVAT / 100).format('0,0.00') }}
									</td>
									<td>kr.</td>
								</tr>
								<tr>
									<td>G.Total</td>
									<td>
										{{
											numeral(dispatch.linesGrandTotal / 100).format('0,0.00')
										}}
									</td>
									<td>kr.</td>
								</tr>
							</table>
						</div>
						<div style="margin-bottom: 140px"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	defineComponent,
	ref,
	reactive,
	computed,
	onMounted,
} from '@vue/composition-api'
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import useRevenueCenterStatisticsState from '@/_srcv2/pages/business-development/statistics-pages/revenue-center-statistics/scripts/useRevenueCenterStatisticsState.js'
import GetTheCustomerLastFourWeeksStatistics from '@/_srcv2/pages/business-development/statistics-pages/_graphql/GetTheCustomerLastFourWeeksStatistics.graphql'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import numeral from 'numeral'

export default defineComponent({
	name: 'GetTheCustomerLastFourWeeksStatistics',
	components: {
		SubNavBar,
		HeroBox,
	},
	setup() {
		const title = ref('All Customers Last for week delivery statistics')
		const subtitle = ref(
			'You can see all the customer deliveries in last four weeks',
		)
		const color = ref('is-info')
		const { q_customer_id, q_address_id, q_bd, q_ed } =
			useRevenueCenterStatisticsState()
		// ? -----------------------------------------------------------
		const customer = ref({})
		const dispatches = ref([])
		const columns = ref([
			{
				label: 'Product',
				field: 'lineInfo',
				sortable: false,
			},
			{
				label: 'Amount',
				field: 'amount',
				sortable: false,
			},
			{
				label: 'Price',
				field: 'price',
				sortable: false,
			},
			{
				label: 'Total',
				field: 'lineTotal',
				sortable: false,
			},
			{
				label: 'V.A.T.',
				field: 'lineVAT',
				sortable: false,
			},
			{
				label: 'G.Total.',
				field: 'lineGrandTotal',
				sortable: false,
			},
			{
				label: 'Exc.',
				field: 'lineExchangeUnit',
				sortable: false,
			},
		])
		// ? -----------------------------------------------------------
		// todo map the date comes from query
		const mapTheCustomer = (customer) => {
			const cda = customer.dispatch_headers[0]._dispatch_address
			console.log('cda is: ', cda)
			return {
				customerInfo: `${customer.customer_id} - ${customer.customer_title} - ${customer.customer_nickname}`,
				addressNickName: cda.address_nickname,
				address: `${cda.line_1} ${cda.line_2}, ${cda.post_code} ${cda.city} ${cda.state} ${cda.country}`,
				dispatchAmount: customer.dispatch_headers_aggregate.aggregate.count,
			}
		}
		// ? -----------------------------------------------------------
		const mapDispatches = (dispatches) => {
			console.log('in mapDispatches dispatches is: ', dispatches)
			return dispatches.map((dispatch) => {
				console.log(' dispatch is: ', dispatch)
				let lines = dispatch._goods_transactions
				console.log(' lines is: ', lines)
				let sums = dispatch._goods_transactions_aggregate.aggregate.sum
				console.log(' sums is: ', sums)
				return {
					dispatchNumber: dispatch.dispatch_number,
					dispatchDate: dispatch.dispatch_date,
					dispatchExchangeUnit: dispatch.dispatch_exchange_unit,
					dispatchLines: mapDispatchLines(
						lines,
						dispatch.dispatch_exchange_unit,
					),
					linesTotal: sums.line_price_total_credit_exchange,
					linesVAT: sums.vat_credit_exchange,
					linesGrandTotal: Math.round(
						sums.line_price_total_credit_exchange + sums.vat_credit_exchange,
					),
				}
			})
		}
		const mapDispatchLines = (lines, exc) => {
			console.log('in mapDispatchLines lines is: ', lines)
			return lines.map((line) => {
				console.log(' line is: ', line)
				return {
					lineInfo: line.line_info,
					amount: line.amount_credit,
					price: line.invoice_price,
					lineTotal: line.line_price_total_credit_exchange,
					lineVAT: line.vat_credit_exchange,
					lineGrandTotal:
						line.line_price_total_credit_exchange + line.vat_credit_exchange,
					lineExchangeUnit: exc,
				}
			})
		}
		// todo Fetch the data from the GraphQL API
		onMounted(() => {
			console.log('onMounted queryVariables is: ', queryVariables.value)
			refetch()
		})
		// ? ----------------------------------------------------------------
		const queryVariables = computed(() => {
			return {
				company_id: Store.getters.getUserCurrentCompany,
				customer_id: q_customer_id.value,
				ed: q_ed.value,
				bd: q_bd.value,
				dispatch_address_id: q_address_id.value,
			}
		})
		// ? ----------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch, onResult } = useQuery(
			GetTheCustomerLastFourWeeksStatistics,
			() => queryVariables.value,
			options,
		)
		onResult((result) => {
			console.log('result', result.data?.customers)
			customer.value = mapTheCustomer(result.data.customers[0])
			console.log('customer', customer.value)
			dispatches.value = mapDispatches(
				result.data?.customers[0]?.dispatch_headers,
			)
			console.log('dispatches', dispatches.value)
		})
		// todo -----------------------------------------------------------

		// todo Return the reactive data to the template
		return {
			title,
			subtitle,
			color,
			queryVariables,
			customer,
			dispatches,
			columns,
			numeral,
		}
	},
})
</script>
<style scoped>
.my-table {
	border-collapse: collapse;
	width: auto;
	float: right;
}

.my-table th,
.my-table td {
	border: 1px solid rgb(243, 244, 247);
	padding: 8px;
	text-align: left;
}

.my-table td:nth-child(2) {
	text-align: right;
}

.my-table tr:nth-child(even) {
	background-color: #f2f2f2;
}
</style>
