<template>
	<div v-if="isPopupVisible">
		<VePopup :closePopup="onClosePopup">
			<template #header>
				<div>
					{{ formName }}
				</div>
			</template>
			<template #body>
				<GetTheCustomerLastFourWeeksStatistics />
			</template>
			<template #footer>
				<div>
					{{ formName }}
				</div>
			</template>
		</VePopup>
	</div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import VePopup from '@/_srcv2/components/_shared/popup/VePopup.vue'
import GetTheCustomerLastFourWeeksStatistics from '@/_srcv2/pages/business-development/statistics-pages/revenue-center-statistics/components/GetTheCustomerLastFourWeeksStatistics.vue'
import useRevenueCenterStatisticsState from '@/_srcv2/pages/business-development/statistics-pages/revenue-center-statistics/scripts/useRevenueCenterStatisticsState.js'

export default {
	name: 'GetTheCustomerLastFourWeeksStatisticsFrame',
	components: {
		VePopup,
		GetTheCustomerLastFourWeeksStatistics,
	},
	setup() {
		const formName = ref("Customer's Last four weeks dispatches Preview")
		const { isDispatchesVisible } = useRevenueCenterStatisticsState()
		const onClosePopup = () => {
			isDispatchesVisible.value = false
			isPopupVisible.value = false
		}
		const isPopupVisible = ref(false)
		onMounted(() => {
			isPopupVisible.value = false
			setTimeout(() => {
				isPopupVisible.value = true
			}, 1000)
		})
		return {
			formName,
			onClosePopup,
			isPopupVisible,
		}
	},
}
</script>

<style lang="scss" scoped></style>
