<template>
	<LayoutWide>
		<div>
			<sub-nav-bar page="Marketing" />
			<div>
				<HeroBox :title="title" :subtitle="subtitle" :color="color" />
			</div>
			<div>
				<div style="background-color: rgb(76, 92, 122)">
					<div class="table-header">{{ tableTitle }}</div>
					<div style="margin-bottom: 30px; margin-top: 10px">
						<b-dropdown :triggers="['hover']" aria-role="list">
							<template #trigger>
								<b-button
									label=""
									type="is-danger"
									class="width-250"
									icon-right="menu-down"
									style="margin-left: 20px"
								>
									{{ buttonTag }}
								</b-button>
							</template>
							<b-dropdown-item aria-role="listitem" @click="callEveryWeek"
								>Call Every Week</b-dropdown-item
							>
							<b-dropdown-item aria-role="listitem" @click="callMonthly"
								>Call Monthly</b-dropdown-item
							>
							<b-dropdown-item aria-role="listitem" @click="visitMonthly"
								>Visit Monthly</b-dropdown-item
							>
							<b-dropdown-item aria-role="listitem" @click="customerCallsUs"
								>Customer Calls Us</b-dropdown-item
							>
							<b-dropdown-item aria-role="listitem" @click="getOthers"
								>Other Customers</b-dropdown-item
							>
							<!-- <b-dropdown-item aria-role="listitem" @click="getAll"
							>Get All</b-dropdown-item
						> -->
						</b-dropdown>
					</div>
					<div v-if="showTable">
						<vue-good-table
							:columns="columns"
							:rows="rows"
							:line-numbers="true"
							@on-row-dblclick="onRowDoubleClick"
							@on-cell-click="onCellClick"
							styleClass="vgt-table striped bordered"
							theme="black-rhino"
							max-height="700px"
							:fixed-header="true"
							:search-options="{
								enabled: true,
							}"
							:sort-options="sortOptions"
							:pagination-options="{
								enabled: true,
								mode: 'records',
								perPage: '5',
								position: 'bottom',
								perPageDropdown: [5, 10, 15, 20, 100],
								dropdownAllowAll: true,
								setCurrentPage: 1,
								jumpFirstOrLast: true,
								firstLabel: 'First',
								lastLabel: 'Last',
								nextLabel: 'next',
								prevLabel: 'prev',
								rowsPerPageLabel: 'Rows per page',
								ofLabel: 'of',
								pageLabel: 'page', // for 'pages' mode
								allLabel: 'All',
							}"
						>
							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field === 'count'">
									<p
										v-if="props.row[props.column.field] === 0"
										class="text-bold text-red text-align-center"
									>
										No business
									</p>
									<p v-else class="text-bold text-align-right">
										{{ props.row[props.column.field] }}
									</p>
								</span>
								<span
									v-else-if="props.column.field === 'customer'"
									@mouseover="hover = true"
									@mouseleave="hover = false"
								>
									<b-tooltip
										label="Click to view the Dispatches"
										position="is-right"
									>
										<p
											class="special text-bold text-align-left"
											style="text-decoration: underline; color: purple"
										>
											{{ props.row[props.column.field] }}
										</p>
									</b-tooltip>
								</span>
								<span v-else-if="props.column.field === 'day'">
									<p class="text-align-center">
										{{ props.row[props.column.field] }}
									</p>
								</span>
								<span v-else-if="props.column.field === 'route'">
									<p class="text-bold text-align-center text-purple">
										{{ props.row[props.column.field] }}
									</p>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
							<template slot="table-column" slot-scope="props">
								<span v-if="props.column.label !== 'Nothing'">
									<p class="text-bold text-align-center">
										{{ props.column.label }}
									</p>
								</span>
								<span v-else>
									{{ props.column.label }}
								</span>
							</template>
						</vue-good-table>
					</div>
					<div
						style="
							background-color: rgb(76, 92, 122);
							padding-bottom: 40px;
							display: flex;
							justify-content: space-between;
						"
					>
						<div>
							<b-button
								class="margin-left-15 margin-top-30 width-250"
								style="background-color: rgb(109, 65, 161); color: white"
								@click="resetSortOptions"
							>
								Reset the sort options
							</b-button>
						</div>
						<div>
							<download-csv :data="rows" :name="fileName">
								<div class="margin-top-30" style="margin-right: 15px">
									<b-button
										type="is-danger"
										outlined
										class="width-250 margin-right-15"
									>
										Download the List
									</b-button>
								</div>
							</download-csv>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isDispatchesVisible" :key="keyFrame">
				<GetTheCustomerLastFourWeeksStatisticsFrame />
			</div>
		</div>
	</LayoutWide>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from '@vue/composition-api'
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import GetTheCustomerLastFourWeeksStatisticsFrame from '@/_srcv2/pages/business-development/statistics-pages/revenue-center-statistics/components/GetTheCustomerLastFourWeeksStatisticsFrame.vue'
import GetCountDispatchesByRevenueCenters from '@/_srcv2/pages/business-development/statistics-pages/_graphql/GetCountDispatchesByRevenueCentersQuery.graphql'
import { useQuery } from '@vue/apollo-composable'
import useRevenueCenterStatisticsState from '@/_srcv2/pages/business-development/statistics-pages/revenue-center-statistics/scripts/useRevenueCenterStatisticsState.js'
import Store from '@/store'

export default defineComponent({
	name: 'GetCountDispatchesByRevenueCenters',
	components: {
		SubNavBar,
		HeroBox,
		GetTheCustomerLastFourWeeksStatisticsFrame,
	},
	setup() {
		const title = ref('The Amount of Dispatches By Revenue Centers')
		const subtitle = ref(
			'You can see the amount of last 28 days dispatches by revenue centers',
		)
		const color = ref('is-info')
		const tableTitle = ref('Count Dispatches of the revenue centers')
		const fileName = ref('count-dispatches.csv')
		const {
			isDispatchesVisible,
			keyFrame,
			q_customer_id,
			q_address_id,
			q_bd,
			q_ed,
		} = useRevenueCenterStatisticsState()
		isDispatchesVisible.value = false
		// ? -----------------------------------------------------------
		// todo Calculate today's date
		const today = new Date()
		// todo Format today's date as 'yyyy-mm-dd'
		const yearToday = today.getFullYear()
		const monthToday = String(today.getMonth() + 1).padStart(2, '0')
		const dayToday = String(today.getDate()).padStart(2, '0')
		const formattedToday = ref(`${yearToday}-${monthToday}-${dayToday}`)

		// todo Calculate the date 28 days before today
		const priorDate = new Date()
		priorDate.setDate(today.getDate() - 28)
		// todo Format the date as 'yyyy-mm-dd'
		const year = priorDate.getFullYear()
		const month = String(priorDate.getMonth() + 1).padStart(2, '0') // Months are zero-based
		const day = String(priorDate.getDate()).padStart(2, '0')
		const formattedDate = ref(`${year}-${month}-${day}`)
		// todo map the date comes from query
		const getDayName = (day) => {
			switch (day) {
				case 1:
					return 'Monday'
				case 2:
					return 'Tuesday'
				case 3:
					return 'Wednesday'
				case 4:
					return 'Thursday'
				case 5:
					return 'Friday'
				case 6:
					return 'Saturday'
				default:
					return
			}
		}
		const mapTheData = (data) => {
			return data.map((item) => {
				let dayNumber = item?._cities?._adresses_route?.dayOfWeek
				return {
					customer: `${item?.customer?.customer_id} - ${item?.customer?.customer_title} - ${item?.customer?.customer_nickname}`,
					count: item?._dispatch_headers_aggregate.aggregate.count,
					day: `(${dayNumber}) ${getDayName(dayNumber)}`,
					route: item?._cities?.route_id_by_city,
					addressNickname: item?.address_nickname,
					address: `${item?.line_1} ${item?.line_2}, ${item?.post_code} ${item?.city} ${item?.state} ${item?.country}`,
					listGroup: item?.list_group,
					// ----------------------------------------------------------
					customerId: item?.customer?.customer_id,
					addressId: item?.address_id,
				}
			})
		}
		// ? ------------------------------------------------------------
		const columns = reactive([
			{
				label: 'Customer',
				field: 'customer',
				sortable: true,
			},
			{
				label: 'Count',
				field: 'count',
				sortable: true,
				width: '120px',
			},
			{
				label: 'Day of Week',
				field: 'day',
				sortable: true,
				width: '140px',
			},
			{
				label: 'Route',
				field: 'route',
				sortable: true,
			},
			{
				label: 'Address',
				field: 'address',
				sortable: true,
				html: true,
			},
		])
		const sortOptions = reactive({
			enabled: true,
			multipleColumns: true,
			initialSortBy: [
				{ field: 'count', type: 'asc' },
				{ field: 'day', type: 'asc' },
				{ field: 'route', type: 'asc' },
				{ field: 'address', type: 'asc' },
			],
		})
		// todo Fetch the data from the GraphQL API
		const resetSortOptions = () => {
			showTable.value = false
			sortOptions.initialSortBy = [
				{ field: 'count', type: 'asc' },
				{ field: 'day', type: 'asc' },
				{ field: 'route', type: 'asc' },
				{ field: 'address', type: 'asc' },
			]
			setTimeout(() => {
				showTable.value = true
			}, 1000)
		}
		onMounted(() => {
			buttonTag.value = 'Call Every Week'
			isDispatchesVisible.value = false
			refetch()
			resetSortOptions()
			showTable.value = true
			console.log('onMounted is called sortOptions', sortOptions)
		})
		const showTable = ref(false)
		// ? ----------------------------------------------------------------
		const queryVariables = reactive({
			company_id: Store.getters.getUserCurrentCompany,
			ed: formattedToday.value,
			bd: formattedDate.value,
			list_group: 'Call Every Week',
		})
		// ? ----------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch, onResult } = useQuery(
			GetCountDispatchesByRevenueCenters,
			() => queryVariables,
			options,
		)
		const rows = ref([])
		onResult((result) => {
			console.log('result', result.data?.addresses)
			rows.value = mapTheData(result.data?.addresses)
		})
		// todo -----------------------------------------------------------
		const buttonTag = ref('')
		const GetTableData = () => {
			console.log('GetTableData is fired')
			refetch()
		}
		const callEveryWeek = () => {
			queryVariables.list_group = 'Call Every Week'
			buttonTag.value = 'Call Every Week'
			GetTableData()
		}
		const callMonthly = () => {
			queryVariables.list_group = 'Call Monthly'
			GetTableData()
			buttonTag.value = 'Call Monthly'
		}

		const visitMonthly = () => {
			queryVariables.list_group = 'Visit Monthly'
			GetTableData()
			buttonTag.value = 'Visit Monthly'
		}
		const customerCallsUs = () => {
			queryVariables.list_group = 'Customer Calls Us'
			GetTableData()
			buttonTag.value = 'Customer Calls Us'
		}
		const getOthers = () => {
			queryVariables.list_group = 'Other Customers'
			GetTableData()
			buttonTag.value = 'Other Customers'
		}
		// todo -----------------------------------------------------------
		const onCellClick = (params) => {
			console.log('onCellClick is fired params.row', params.row)
			if (params.column.field === 'customer') {
				if (params.row.count > 0) {
					q_customer_id.value = params.row.customerId
					q_address_id.value = params.row.addressId
					q_bd.value = formattedDate.value
					q_ed.value = formattedToday.value
					isDispatchesVisible.value = true
					keyFrame.value += 1
				} else {
					alert('No Dispatches Found')
				}
			}
		}
		const onRowDoubleClick = (params) => {
			if (params.row.count > 0) {
				q_customer_id.value = params.row.customerId
				q_address_id.value = params.row.addressId
				q_bd.value = formattedDate.value
				q_ed.value = formattedToday.value
				isDispatchesVisible.value = true
				keyFrame.value += 1
			} else {
				alert('No Dispatches Found')
			}
		}
		// todo Return the reactive data to the template
		return {
			title,
			subtitle,
			color,
			columns,
			rows,
			sortOptions,
			showTable,
			tableTitle,
			fileName,
			// -----------------------------------------------------------------
			buttonTag,
			callEveryWeek,
			callMonthly,
			visitMonthly,
			customerCallsUs,
			getOthers,
			// -----------------------------------------------------------------
			resetSortOptions,
			isDispatchesVisible,
			keyFrame,
			// -----------------------------------------------------------------
			onCellClick,
			onRowDoubleClick,
		}
	},
})
</script>
<style scoped>
.special {
	cursor: pointer;
}
</style>
